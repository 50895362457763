<template>
  <div class="C_premium-card-component">
    <div class="C_premium-card-top-container">
      <p class="C_premium-card-price-text">
        {{ price && Number(price.unit_amount/100).toFixed(0) }}€
        <span v-if="isYearly">{{ $t('PremiumCard.perYear') }}</span>
        <span v-else>{{ $t('PremiumCard.perMonth') }}</span>
      </p>
      <MonthlyOrYearly @yearly="isYearly = $event" />
    </div>
    <img
      class="discount-arrow-img"
      src="@/assets/img/save-2-months-arrow@2x.png"
    >
    <p class="discount-text">
      {{ $t('PremiumCard.save2Months') }}
    </p>
    <div style="margin-top: 30px;">
      <ul class="C_premium-card-list">
        <li
          v-for="index in freeListLength"
          :key="index"
        >
          {{ $t(`PremiumCard.freeList.${index}`) }}
        </li>
      </ul>
    </div>
    <div style="display: flex; margin-top: 30px;">
      <ds-button
        size="large"
        :disabled="loading"
        @click="$emit('pay')"
      >
        {{ $t('PremiumCard.upgradeButton') }}
      </ds-button>
      <vue-loading
        v-if="loading"
        type="bars"
        color="#20bf6b"
      />
    </div>
    <img
      class="rocket-img"
      src="@/assets/img/rocket.svg"
    >
  </div>
</template>

<script>
import MonthlyOrYearly from '@/components/MonthlyOrYearly.vue'
import fr from '@/i18n/fr.json'

export default {
  components: {
    MonthlyOrYearly
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isYearly: false,
      freeListLength: Object.keys(fr.PremiumCard.freeList).length
    }
  },
  computed: {
    price () {
      return this.$store.getters['account/price'](this.isYearly)
    }
  },
  watch: {
    price () {
      this.$emit('price', this.price)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/commons/premium-card.scss";

.rocket-img {
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 160px;
  height: 182px;

  @include below(small) {
    display: none;
  }
}

.discount-arrow-img {
  display: block;

  @include below(medium) {
    width: 48px;
    height: 10px;
    position: relative;
    top: 16px;
    left: 128px;
    transform: rotate(30deg);
  }

  @include above(medium) {
    width: 63px;
    height: 13px;
    position: absolute;
    top: $app-header-height;
    right: -44px;
  }
}

.discount-text {
  display: inline-block;
  color: $color-gray-mid;
  font-family: $Caveat;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.11px;

  @include below(medium) {
    position: relative;
    top: 8px;
    left: 180px;
    width: 70px;
  }

  @include above(medium) {
    position: absolute;
    top: 44px;
    left: 446px;
    white-space: nowrap;
  }
}
</style>
