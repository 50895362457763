<template>
  <div
    v-if="!loading"
    class="C_premium-card-component"
  >
    <div class="C_premium-card-top-container">
      <template v-if="hasStripeSubscription">
        <p class="C_premium-card-price-text">
          {{ amount }}€
          <span v-if="premium.interval">/{{ $t(`PremiumCard.${premium.interval}`) }}</span>
        </p>
        <ds-button
          size="medium"
          danger
          outlined
          :loading="cancelloading"
          @click="cancel"
        >
          {{ $t('PremiumCard.stopPremiumButton') }}
        </ds-button>
      </template>
      <template v-else>
        <p class="C_premium-card-price-text">
          {{ $t('PremiumCard.youArePremium') }}
        </p>
      </template>
    </div>
    <template v-if="hasStripeSubscription">
      <div style="margin-top: 38px;">
        <p class="C_premium-card-label-text">
          {{ $t('PremiumCard.premiumSince') }}
        </p>
        <p class="C_premium-card-date-text">
          {{ premium.created * 1000 | datetime }}
        </p>
      </div>
      <div style="margin-top: 20px;">
        <p class="C_premium-card-label-text">
          {{ $t('PremiumCard.nextRenewAt') }}
        </p>
        <p class="C_premium-card-date-text">
          {{ premium.current_period_end * 1000 | datetime }}
        </p>
      </div>
      <div style="margin-top: 20px;">
        <p class="C_premium-card-label-text">
          {{ $t('PremiumCard.lastCreditCardUsed') }}
        </p>
        <ds-input
          v-model="maskedCardNumber"
          type="text"
          gray
          readonly
          style="margin-top: 5px;"
        />
      </div>
      <div style="margin-top: 30px">
        <ds-button
          size="medium"
          :loading="portalLoading"
          @click="portal"
        >
          {{ $t('PremiumCard.updateCardDetails') }}
        </ds-button>
      </div>
    </template>
    <template v-else>
      <div style="margin-top: 20px;">
        <p class="C_premium-card-label-text">
          {{ $t('PremiumCard.endOfPremium') }}
        </p>
        <p class="C_premium-card-date-text">
          {{ $store.getters['session/userPremiumEndsAt'] | datetime }}
        </p>
      </div>

      <p
        class="C_premium-card-label-text"
        style="margin-top: 30px"
      >
        {{ $t('PremiumCard.oldConfcallrPremiumUserInfo') }}
      </p>
      <div style="margin-top: 30px">
        <ds-button
          danger
          size="medium"
          :loading="cancelloading"
          @click="cancel"
        >
          {{ $t('PremiumCard.cancelNow') }}
        </ds-button>
      </div>
    </template>
    <img
      class="rocket-img"
      src="@/assets/img/rocket.svg"
    >
  </div>
  <vue-loading
    v-else
    type="bars"
    color="#20bf6b"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      loading: false,
      premium: {},
      portalLoading: false,
      cancelloading: false
    }
  },
  computed: {
    ...mapGetters('session', [
      'hasStripeSubscription'
    ]),
    maskedCardNumber () {
      return this.premium.last4
        ? `xxxx xxxx xxxx ${this.premium.last4}`
        : 'xxxx xxxx xxxx xxxx'
    },
    amount () {
      return Number(this.premium.amount / 100).toFixed(0)
    }
  },
  async mounted () {
    // the user might be premium, maybe it was from before Stripe...
    if (!this.hasStripeSubscription) return

    this.loading = true

    try {
      this.premium = await this.$store.dispatch('account/fetchSubscription')
    } catch (err) {
      this.$store.commit('app/openErrorPopin', err)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async portal () {
      try {
        this.portalLoading = true
        const { url } = await this.$store.dispatch('account/portal')

        window.location = url
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
        this.portalLoading = false
      }
    },
    async cancel () {
      if (!confirm(this.$t('PremiumCard.confirmStopPremium'))) {
        return
      }

      this.cancelloading = true

      try {
        await this.$store.dispatch('account/cancelSubscription')
        this.$store.commit('app/openInfoPopin', {
          message: this.$t('PremiumCard.premiumCanceled')
        })
        await this.$store.dispatch('account/refresh')
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      } finally {
        this.cancelloading = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/commons/premium-card.scss";

.rocket-img {
  position: absolute;
  top: 300px;
  right: -30px;
  width: 130px;
  height: 148px;

  @include below(small) {
    display: none;
  }
}
</style>
