<template>
  <div class="root">
    <vue-loading
      v-if="loading"
      type="bars"
      color="#20bf6b"
    />
    <template v-else-if="success">
      <p class="success">
        {{ $t('Stripe.congratulations') }}<br>
        <br>
        {{ $t('Stripe.youAreNowPremium') }}
      </p>

      <p class="small">
        {{ $t('Stripe.accountRedirection') }}
      </p>
    </template>
    <template v-else-if="error">
      {{ $t('Stripe.errorOccured') }}
    </template>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      success: false,
      error: false
    }
  },
  async mounted () {
    const result = await this.$store.dispatch('account/verifyCheckoutSession', {
      sessionId: this.$route.query.session_id
    })

    this.loading = false

    if (result.success) {
      this.success = true

      // this will set the stripeSubId
      await this.$store.dispatch('account/refresh')
      window.setTimeout(() => this.$router.push('/profile'), 8000)
    } else {
      this.error = true
    }
  }
}
</script>

<style scoped>
.root {
  margin: 40px;
  text-align: center;
}
.success {
  font-size: 14pt;
}
.small {
  font-size: smaller;
}
</style>
