<template>
  <div class="view-root">
    <div
      v-if="$store.state.session.guest"
      class="guest-container"
    >
      <h1>{{ $t('Profile.withoutAccountHeading') }}</h1>
      <ds-button
        size="large"
        @click="loginWithAccount"
      >
        {{ $t('Profile.withAccountHeading') }}
      </ds-button>
    </div>
    <template v-else>
      <div class="top-container">
        <div class="forms-container">
          <form
            class="form"
            @submit.prevent="onUserInfoSubmit"
          >
            <p class="T_medium-heading">
              {{ $t('Profile.modifyProfileHeading') }}
            </p>
            <div
              class="form-duo-of-fields-container"
              style="margin-top: 10px;"
            >
              <ds-input
                v-model.trim="editUserName"
                type="text"
                :placeholder="$t('Profile.userNamePlaceholder')"
                required
              />
              <ds-input
                v-model.trim="editUserCompany"
                type="text"
                :placeholder="$t('Profile.userCompanyPlaceholder')"
              />
            </div>
            <div class="form-field-container">
              <ds-input
                v-model.trim="editUserEmail"
                type="email"
                :placeholder="$t('Profile.userEmailPlaceholder')"
                required
              />
            </div>
            <div class="form-field-container">
              <ds-input
                v-model.trim="editUserAddressStreet"
                type="text"
                :placeholder="$t('Profile.userAddressStreetPlaceholder')"
              />
            </div>
            <div class="form-duo-of-fields-container">
              <!-- <ds-input
              v-model.trim="editUserAddressState"
              type="text"
              :placeholder="$t('Profile.userAddressStatePlaceholder')"
            /> -->
              <ds-input
                v-model.trim="editUserAddressZip"
                type="text"
                :placeholder="$t('Profile.userAddressZipPlaceholder')"
              />
              <ds-input
                v-model.trim="editUserAddressCity"
                type="text"
                :placeholder="$t('Profile.userAddressCityPlaceholder')"
              />
            </div>
            <div class="form-field-container">
              <select
                v-model="editUserAddressCountry"
                class="DS_input"
              >
                <option
                  v-for="item in $store.state.session.countries"
                  :key="item.country_code"
                  :value="item.country_code"
                >
                  {{ item.country_fr_label }}
                </option>
              </select>
            </div>
            <div class="form-submit-container">
              <vue-loading
                v-if="profileLoading"
                type="bars"
                color="#20bf6b"
              />
              <ds-button
                size="medium"
                :disabled="profileLoading"
              >
                {{ $t('Profile.modifyProfileButton') }}
              </ds-button>
            </div>
          </form>
          <form
            class="form"
            @submit.prevent="resetPassword"
          >
            <p class="T_medium-heading">
              {{ $t('Profile.modifyPasswordHeading') }}
            </p>
            <div class="form-lonely-button-container">
              <ds-button
                size="medium"
                :disabled="passwordLoading"
              >
                {{ $t('Profile.modifyPasswordButton') }}
              </ds-button>
              <vue-loading
                v-if="passwordLoading"
                type="bars"
                color="#20bf6b"
              />
            </div>
          </form>
          <form
            class="form"
            @submit.prevent="deleteAccount"
          >
            <p class="T_medium-heading">
              {{ $t('Profile.deleteAccountHeading') }}
            </p>
            <div class="form-lonely-button-container">
              <ds-button
                size="medium"
                :disabled="deleteLoading"
                danger
              >
                {{ $t('Profile.deleteAccountButton') }}
              </ds-button>
              <vue-loading
                v-if="deleteLoading"
                type="bars"
                color="#20bf6b"
              />
            </div>
          </form>
        </div><!-- /.forms-container -->
        <div
          v-if="userPremium"
          class="premium-card-container"
        >
          <PremiumCard />
        </div>
        <div
          v-else
          class="premium-card-container"
        >
          <PremiumCardFree
            :loading="premiumLoading"
            @pay="pay"
            @price="price = $event"
          />
          <div
            v-if="errorBillingAddress"
            class="error-billing-address"
          >
            {{ $t('Profile.mandatoryBillingAddress') }}
          </div>
        </div>
      </div><!-- /.top-container -->
      <div class="payments-history-container">
        <PaymentsHistory :invoices="invoices" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { loadStripe } from '@stripe/stripe-js'
import PremiumCard from '@/components/PremiumCard.vue'
import PremiumCardFree from '@/components/PremiumCardFree.vue'
import PaymentsHistory from '@/components/PaymentsHistory.vue'

export default {
  name: 'Profile',
  components: {
    PremiumCard,
    PremiumCardFree,
    PaymentsHistory
  },
  data () {
    return {
      price: undefined,
      profileLoading: false,
      passwordLoading: false,
      deleteLoading: false,
      premiumLoading: false,
      editUserName: '',
      editUserEmail: '',
      editUserCompany: '',
      editUserAddressStreet: '',
      editUserAddressState: '',
      editUserAddressZip: '',
      editUserAddressCity: '',
      editUserAddressCountry: '',
      oldPassword: '',
      newPassword: '',
      newPasswordBis: '',
      errorBillingAddress: false
    }
  },
  computed: {
    ...mapState('session', {
      userPremium: (state) => state.userPremium,
      userName: (state) => state.userName,
      userEmail: (state) => state.userEmail,
      userCompany: (state) => state.userCompany,
      userAddressStreet: (state) => state.userAddressStreet,
      userAddressState: (state) => state.userAddressState,
      userAddressZip: (state) => state.userAddressZip,
      userAddressCity: (state) => state.userAddressCity,
      userAddressCountry: (state) => state.userAddressCountry
    }),
    ...mapState('account', {
      invoices: (state) => state.invoices
    })
  },
  mounted () {
    this.editUserName = this.userName
    this.editUserEmail = this.userEmail
    this.editUserCompany = this.userCompany
    this.editUserAddressStreet = this.userAddressStreet
    this.editUserAddressState = this.userAddressState
    this.editUserAddressZip = this.userAddressZip
    this.editUserAddressCity = this.userAddressCity
    this.editUserAddressCountry = this.userAddressCountry

    this.$store.dispatch('account/fetchInvoices')
  },
  methods: {
    async onUserInfoSubmit () {
      this.profileLoading = true
      this.errorBillingAddress = false

      const properties = {
        name: this.editUserName,
        email: this.editUserEmail,
        company: this.editUserCompany,
        locale: this.$store.state.session.userLocale, // TODO on this page
        address: {
          street: this.editUserAddressStreet,
          zipcode: this.editUserAddressZip,
          city: this.editUserAddressCity,
          country: this.editUserAddressCountry
        }
      }

      try {
        await this.$store.dispatch('account/edit', properties)
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      } finally {
        this.profileLoading = false
      }
    },
    async resetPassword () {
      try {
        this.passwordLoading = true
        await this.$store.dispatch('account/resetPassword', { email: this.userEmail })
        this.$store.commit('app/openInfoPopin', {
          message: this.$t('Profile.changePasswordSuccess')
        })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      } finally {
        this.passwordLoading = false
      }
    },
    async deleteAccount () {
      if (!confirm(this.$t('Profile.deleteAccountConfirmation'))) {
        return
      }

      try {
        this.deleteLoading = true
        await this.$store.dispatch('account/delete')
        this.$store.commit('app/openInfoPopin', {
          message: this.$t('Profile.deleteAccountSuccess')
        })
        this.$router.push({ name: 'Logout' })
      } catch (err) {
        this.$store.commit('app/openErrorPopin', err)
      } finally {
        this.deleteLoading = false
      }
    },
    async loginWithAccount () {
      await this.$store.dispatch('session/logout')
      this.$router.push('/auth')
    },
    async pay () {
      this.errorBillingAddress = false

      if (!this.editUserAddressStreet ||
      !this.editUserAddressCity ||
      !this.editUserAddressCountry) {
        this.errorBillingAddress = true
        return
      }

      this.premiumLoading = true

      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)

      try {
        const session = await this.$store.dispatch('account/createCheckoutSession', { priceId: this.price.id })
        const result = await stripe.redirectToCheckout({ sessionId: session.id })

        if (result.error) {
          throw new Error(result.error.message)
        }
      } catch (err) {
        this.$store.commit('app/openErrorPopin', {
          message: this.$t(`Errors.${err.message}`)
        })
      } finally {
        this.premiumLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.view-root {
  padding: 20px;

  @include above(small) {
    padding: 50px 30px;
  }
}

.guest-container {
  text-align: center;

  h1 {
    margin: 20px;
    font-size: 16px;
  }
}

.top-container {
  @include above(medium) {
    display: flex;
  }
}

.forms-container {
  flex-shrink: 0;
  width: 400px;
  max-width: 100%;

  @include above(medium) {
    width: 325px;
  }

  @include above(large) {
    width: 500px;
  }
}

.form:not(:first-child) {
  margin-top: 40px;
}

.form-field-container {
  margin-top: 15px;
}

.form-duo-of-fields-container {
  @include below(large) {
    > * {
      margin-top: 10px;
    }
  }

  @include above(large) {
    display: flex;
    align-items: center;
    margin-top: 15px;

    > * {
      flex-grow: 1;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

.form-submit-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.form-lonely-button-container {
  margin-top: 10px;
}

.premium-card-container {
  @include below(medium) {
    margin-top: 40px;
  }

  @include above(medium) {
    margin-left: 50px;
  }

  @include above(large) {
    margin-left: 150px;
  }
}

.payments-history-container {
  margin-top: 40px;

  @include above(medium) {
    margin-top: 100px;
  }
}

.error-billing-address {
  margin-top: 10px;
  padding: 10px;
  color: $color-red;
}
</style>
