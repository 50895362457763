<template>
  <button
    class="toggle-button"
    :class="{ 'toggle-button--is-yearly': isYearly }"
    @click="isYearly = !isYearly"
  >
    <span class="toggle-option">
      {{ $t('MonthlyOrYearly.monthly') }}
    </span>
    <span class="toggle-option">
      {{ $t('MonthlyOrYearly.yearly') }}
    </span>
  </button>
</template>

<script>
export default {
  data () {
    return {
      isYearly: true
    }
  },
  watch: {
    isYearly () {
      this.$emit('yearly', this.isYearly)
    }
  },
  mounted () {
    this.$emit('yearly', this.isYearly)
  }
}
</script>

<style lang="scss" scoped>
.toggle-button {
  position: relative;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  height: 44px;
  background-color: white;
  border: 2px solid $color-fake-white;
  border-radius: 8px;

  &::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 90px;
    height: 38px;
    background-color: $color-blue;
    border-radius: 6px;
    transition: left $standard-transition-duration;
  }
}

.toggle-button--is-yearly::before {
  left: 101px;
}

.toggle-option {
  position: relative;
  width: 92px;
  color: $color-gray-dark;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.11px;
  transition: color $standard-transition-duration;

  &:last-child {
    margin-left: 8px;
  }
}

.toggle-button:not(.toggle-button--is-yearly) .toggle-option:first-child,
.toggle-button.toggle-button--is-yearly .toggle-option:last-child {
  color: white;
}
</style>
