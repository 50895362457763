<template>
  <div v-if="invoices && invoices.length">
    <p class="T_medium-heading">
      {{ $t('PaymentsHistory.heading') }}
    </p>
    <div
      v-for="(invoice, index) in invoices"
      :key="index"
      class="payment-box"
    >
      <p class="payment-label">
        {{ invoice.lines.data[0].description }}
      </p>
      <p class="payment-metadata payment-metadata--datetime">
        {{ invoice.created*1000 | datetime }}
      </p>
      <p class="payment-metadata payment-metadata--number">
        {{ invoice.number }}
      </p>
      <p class="payment-metadata payment-metadata--amount">
        {{ $t('PaymentsHistory.amount') }}
        <strong>
          {{ Number(invoice.total/100).toFixed(2) }}€
        </strong>
      </p>
      <button
        class="payment-download-button"
        @click="invoicePdf(invoice.invoice_pdf)"
      >
        <img
          class="payment-download-button-icon-below-medium"
          src="@/assets/img/invoice-blue-bg.svg"
          width="20"
          height="20"
        >
        <span>
          {{ $t('PaymentsHistory.downloadButton') }}
        </span>
        <img
          class="payment-download-button-icon-above-medium"
          src="@/assets/img/invoice.svg"
          width="24"
          height="24"
        >
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    invoices: {
      type: Array,
      required: true
    }
  },
  methods: {
    invoicePdf (url) {
      window.open(url, '_newtab')
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-box {
  background-color: white;
  border-radius: 20px;

  @include below(medium) {
    margin-top: 20px;
    padding: 15px;
    width: 400px;
    max-width: 100%;
  }

  @include above(medium) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 19px 26px 19px 30px;
  }
}

.payment-label {
  font-size: 14px;
  letter-spacing: 0.1px;
}

.payment-metadata {
  color: $color-gray-mid;
  font-weight: 600;
  font-size: 12px;

  strong {
    color: $color-fake-black;
  }
}

@include below(medium) {
  .payment-metadata--datetime {
    margin-top: 10px;
  }

  .payment-metadata--number {
    display: none;
  }

  .payment-metadata--amount {
    margin-top: 20px;
  }
}

.payment-download-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 600;

  @include below(medium) {
    margin-top: 20px;
    padding: 7px 10px;
    background-color: $color-blue;
    color: white;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
  }

  @include above(medium) {
    padding: 4px;
    color: $color-blue;
    font-size: 12px;
    text-align: right;
  }
}

.payment-download-button-icon-below-medium {
  margin-right: 10px;

  @include above(medium) {
    display: none;
  }
}

.payment-download-button-icon-above-medium {
  margin-left: 20px;

  @include below(medium) {
    display: none;
  }
}
</style>
